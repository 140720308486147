import * as React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Background from "components/Background/Background";
import TitleSubtitle from "components/blocks/TitleSubtitle/TitleSubtitle";
import Gallery from "components/Gallery/Gallery";

const Blogs = ({ data }) => {
  const block = data.datoCmsBlogsoverview;
  const seo = block.seoMetaTags;
  const title = block.title;
  const subtitle = block.subtitle;
  const blogs = data.blogs.nodes;
  const titleSubtitleBlock = {
    title,
    subtitle,
    pageHeader: true,
  };

  return (
    <>
      <HelmetDatoCms seo={seo} />
      <Background
        height={block.height}
        bgColor={block.backgroundColor}
        viewHeight={true}
      >
        <TitleSubtitle block={titleSubtitleBlock} />
        <Gallery items={blogs} />
      </Background>
    </>
  );
};

export const query = graphql`
  query {
    datoCmsBlogsoverview {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      navbarColors
      title
      subtitle
      height
      backgroundColor {
        hex
      }
    }
    blogs: allDatoCmsBlogpage(sort: { fields: meta___firstPublishedAt, order: DESC }) {
      nodes {
        ...BlogPageGalleryItem
      }
    }
  }
`;

export default Blogs;
