import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import * as GalleryItemStyling from "./Gallery.module.scss";
import { date } from "lib/fns.js";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";

const GalleryItem = ({
  title,
  image,
  slug,
  dateString,
  readingTime,
  hasIcon,
}) => {
  return (
    <Link
      to={`/${slug}`}
      className={`col l-4 m-6 sm-12 ${GalleryItemStyling.galleryItem}`}
    >
      <div className={GalleryItemStyling.imageWrapper}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt}
          className={GalleryItemStyling.image}
          imgClassName={GalleryItemStyling.imageRadius}
        />
      </div>
      <div className={GalleryItemStyling.textWrapper}>
        <p className={GalleryItemStyling.title}>
          <span>{title}</span>
          {hasIcon && (
            <Icon name="arrow-right" className={GalleryItemStyling.icon} />
          )}
        </p>
        {dateString && readingTime && (
          <div className={GalleryItemStyling.info}>
            <p>Posted on {date(dateString)}</p>
            <p>Time to read – {readingTime}</p>
          </div>
        )}
      </div>
    </Link>
  );
};

export default GalleryItem;

GalleryItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    time: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      gatsbyImageData: PropTypes.object,
    }),
  }),
};
