import React from "react";
import cn from "classnames"
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

const TitleSubtitle = ({ block }) => {
  const { title, subtitle } = block
  return (
    <Section row="r-middle">
      <div className="col c-12 xl-8">
        {block.pageHeader ? (
          <>
            {title && <h1 className={cn('text8', !subtitle && 'nobottom')}>{title}</h1>}
            {subtitle && <h2 className="text5 nobottom subtle">{subtitle}</h2>}
          </>
        ) : (
          <>
            {title && <p className={cn('text7', !subtitle && 'nobottom')}>{title}</p>}
            {subtitle && <p className="text5 nobottom subtle">{subtitle}</p>}
          </>
        )}
      </div>
    </Section>
  );
};

export default TitleSubtitle;

TitleSubtitle.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    pageheader: PropTypes.bool,
  }),
};

export const query = graphql`
  fragment TitleSubtitle on DatoCmsTitlesubtitle {
    model {
      apiKey
    }
    id: originalId
    title
    subtitle
    pageHeader
  }
`;
