import React from "react";
import PropTypes from "prop-types";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import GalleryItem from "./GalleryItem";
import * as GalleryStyling from "./Gallery.module.scss";
const Gallery = ({ title, items, hasIcon }) => {
  return (
    <Section>
      {title && <p className="text6 mb-7">{title}</p>}
      <div className={`row ${GalleryStyling.galleryWrapper}`}>
        {items.map((item, index) => {
          return (
            <GalleryItem
              key={index}
              title={item.title}
              image={item.galleryItem[0].image}
              slug={item.slug}
              dateString={item.meta && item.meta.firstPublishedAt}
              readingTime={item.readingTime}
              hasIcon={hasIcon}
            />
          );
        })}
      </div>
    </Section>
  );
};

export default Gallery;

Gallery.propTypes = {
  title: PropTypes.string,
  hasIcon: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string,
      slug: PropTypes.string.isRequired,
      readingTime: PropTypes.string,
      dateString: PropTypes.string,
    })
  ),
};
